import React, { useEffect, useState } from 'react';
import { Layout, Card, Typography, Tabs, Button } from 'antd';
import { Icon } from "react-materialize";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import styles from './CardDetailsStyle.module.scss';
import TopHeader from '../TopHeader/TopHeader';
import DetailsTab from './CardDetailsTabs/DetailsTab/DetailsTab';
import VideoCommentsTab from './CardDetailsTabs/VideoCommentsTab/VideoCommentsTab';
import { axiosClient } from '../../api/httpClient';
import YTHelperTab from './YTHelperTab/YTHelperTab';
import './CardDetailsStyle.scss';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const CardDetails = (data) => {
    const [cardDetail, setCardDetail] = useState();
    
    const props = useSelector((state) => state)
    const loggedInUser = props.auth?.loggedInUser;
    let history = useHistory();
    const params = useParams(); // Use useParams here
    const cardId = params.cardId; 
    const fetchCardDetails = () => {
        axiosClient.get('/api/cardDetail/' + cardId)
        .then((res) => {
            setCardDetail(res.data);
        }).catch(err => {
            console.error(err);
        });
    };
    useEffect(() => {
     fetchCardDetails();
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardId])

    const getTabStatus = () => {
        return <div className={styles.tabStatusTitle}>
            <span>YT Helper</span>
            <div className={`roundDot-${cardDetail?.yt_card_status === null ? "assigned" : cardDetail.yt_card_status}`}></div>
        </div>
    }

    return (
        <Layout className={styles.CardDetailsLayout}>
          <Card className={styles.topHeader} loading={false}>
            <TopHeader />
          </Card>
          <div className={styles.container}>
            <Button type="link" className={`${styles.greyFontColor} ${styles.arrowIconAndTextWrapper}`} onClick={() => history.goBack()}>
                <Icon className={styles.BackArrowIcon}>arrow_back</Icon> Back
            </Button>
            <Typography.Title className={styles.cardTitle}>
                {
                    cardDetail?.card_title ? cardDetail?.card_title : ""
                }
            </Typography.Title>
            <Tabs defaultActiveKey={loggedInUser.client_type === 'customer' ? '2' : '1'} tabBarStyle={{color: '#ab322d'}}>
                <Tabs.TabPane tab="Details" key="1" className={styles.detailsTabPane}>
                    <DetailsTab card={cardDetail} setCardDetail={setCardDetail} fetchCardDetails={fetchCardDetails}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Video & Comments" key="2">
                    <VideoCommentsTab card={cardDetail} cardId={cardId} fetchCardDetails={fetchCardDetails} />
                </Tabs.TabPane>
                {cardDetail?.yt_helper_id ? <Tabs.TabPane tab={getTabStatus()} key="3">
                    <YTHelperTab card={cardDetail} setCardDetail={setCardDetail}  viewType={"yt-helper"} />
                </Tabs.TabPane> : null}
            </Tabs>
          </div>

        </Layout>
    );
}

export default CardDetails;
